/* Toast */
.Toastify__toast--default {
    background: #eeeeee;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
  }
  
  .Toastify__toast--info {
    background: #3498db;
    font-weight: 700;
  }
  
  .Toastify__toast--success {
    background: #2e7d32;
    color: #fff !important;
    font-weight: 700;
  }
  
  .Toastify__toast--warning {
    background: #f1c40f;
  }
  
  .Toastify__toast--error {
    background: #c62828;
    color: #fff;
    font-weight: 700;
  }