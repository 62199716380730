@import url(https://fonts.googleapis.com/css?family=Roboto:400;500);

*:focus {
  /*outline: none;*/
  box-shadow: 0 0 0 0.2rem rgba(15, 111, 183, 0.11) !important;
}

body {
  font-weight: 600;
  font-size: 14px;
  font: 400 14px/24px Roboto, Helvetica Neue, sans-serif;
}

.text-none-transform {
  text-transform: none !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.input-group-sm
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-sm > .react-datepicker-wrapper {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group-sm
  > .react-datepicker-wrapper
  > .react-datepicker__input-container {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group
  > .react-datepicker-wrapper
  > .react-datepicker__input-container
  > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

/* sticky-alert */
.sticky-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.endConference{
  border: 2px;
  border-color: #0c2788;
  justify-content: center;
  width: 70px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  padding-top: 20px;
  padding-left: 6px;
  height: 70px;
  background-color: #17a2b8;
  border-radius: 100px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}
.centerdivfixed{
  width: 75%;
  position: fixed;
  left: 50%;
  margin-left: -37.5%;
  top:50px;
}
.endDeliveyProcess{
  border: 2px;
  border-color: #0c2788;
  justify-content: center;
  z-index: 99999999999999;
  width: 70px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  font-weight: bold;
  right: 10px;
  color: #fff;
  font-size: 13px;
  cursor:pointer;
  padding-top: 20px;
  padding-left: 0px;
  height: 70px;
  background-color: #17a2b8;
  border-radius: 100px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.w3-animate-fading{-webkit-animation:fading 10s infinite;animation:fading 10s infinite}@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{-webkit-animation:opac 0.8s;animation:opac 0.8s}@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;-webkit-animation:animatetop 0.4s;animation:animatetop 0.4s}@-webkit-keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;-webkit-animation:animateleft 0.4s;animation:animateleft 0.4s}@-webkit-keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;-webkit-animation:animateright 0.4s;animation:animateright 0.4s}@-webkit-keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;-webkit-animation:animatebottom 0.4s;animation:animatebottom 0.4s}@-webkit-keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {-webkit-animation:animatezoom 0.6s;animation:animatezoom 0.6s}@-webkit-keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.font-small{
  font-size: 10px;
}
.div-radius{
  border-radius: 10px;
}

.displaynone{
 display: none;
}

.qrcode_limited{
  border: 4px dashed #ffffff;
  position: absolute;
  z-index: 1000;
  margin:45px;
  width: 200px;
  height: 250px;
  color:#ffffff;
  text-align: center;
  font-size: 8px;
}

.qrcode_limited_delivery{
  border: 4px dashed #ffffff;
  position: absolute;
  z-index: 1000;
  margin:45px;
  margin-left: 70px;
  width: 200px;
  height: 250px;
  color:#ffffff;
  text-align: center;
  font-size: 8px;
}

.text10{
  font-size: 10px;
}

.text12{
  font-size: 12px;
}

.div-effect{
  padding: 1px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.toggle > input {
  display: none;
}

.toggle > label {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  background: #898989;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.toggle > label:after {
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
  content: '';
  transition: all 0.3s ease;
}
.toggle > label:active:after {
  -webkit-transform: scale(1.15, 0.85);
          transform: scale(1.15, 0.85);
}
.toggle > input:checked ~ label {
  background: #6fbeb5;
}
.toggle > input:checked ~ label:after {
  left: 20px;
  background: #179588;
}
.toggle > input:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
}
.toggle > input:disabled ~ label:after {
  background: #bcbdbc;
}
.bg-gray{
background-color: #f0f0f0;
}
.backdelivery{
  position: fixed;
  left: 28px;
  bottom: 10px;
  z-index: 999999999;
  width: 25px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  padding-left: 4px;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
}
.div-roll{
  height: 100px;
  overflow-y: auto;
  background-color: #f8f8ff;
  border: 2px dashed #d0d0d0;
}

.div-height200{
  height: 280px;
}
.endTradein{
  border: 2px;
  border-color: #0c2788;
  justify-content: center;
  width: 70px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  padding-top: 12px;
  padding-left: 3px;
  height: 70px;
  background-color: #17a2b8;
  border-radius: 100px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
}
.transparent{
  opacity: 0.9;
}
.strtoupper{
  text-transform: uppercase;
}
.topphoto{
  background-color: #cbc9c9;
  border-radius: 20px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
  margin-left: 10px;
  margin-right: 10px;
}
.colorred{
  color: #e72525;
}
.w90p{
  width: 90%;
}
.bottomRadiosDiv {
  background-color: #007bff;
  color: #fff;
  border-radius: 18px;
  border:1px solid #d0d0d0;
  padding-left: 10px;
  padding-top: 6px;
  width: 35px;
  height: 35px;
}
.box{
  position: absolute;
  background-color: #e5e5e5;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999999998;
}
.border-dashed{
  border-top: 1px dashed #d0d0d0;
  border-bottom: 1px dashed #d0d0d0;
}
.sigCanvas {
  width: 500px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #ff0000;
}
.text-grande-rolagem {
  height: 200px;
  overflow-y: scroll;
}
.font-14{
  font-size: 14px;
}
/* Toast */
.Toastify__toast--default {
    background: #eeeeee;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 700;
  }
  
  .Toastify__toast--info {
    background: #3498db;
    font-weight: 700;
  }
  
  .Toastify__toast--success {
    background: #2e7d32;
    color: #fff !important;
    font-weight: 700;
  }
  
  .Toastify__toast--warning {
    background: #f1c40f;
  }
  
  .Toastify__toast--error {
    background: #c62828;
    color: #fff;
    font-weight: 700;
  }
